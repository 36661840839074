import React from "react";

const BaseHeadline = ({text, Tag = 'span', children}) => {
  return (
    <Tag className={'base-headline'}>
      {text ?? children}
    </Tag>
  )
}

export default BaseHeadline
