import React from "react";

const BaseCaption = React.forwardRef(({text, Tag = 'p', isSmall = false, children, props = {}}, ref) => {
  return (
    <Tag
      ref={ref}
      className={isSmall ? 'base-caption-2' : 'base-caption'}
      {...props}
    >
      {text ?? children}
    </Tag>
  )
})

export default BaseCaption
