import AppConstants from "../app_constants";

const getHeaders = () => {
  const initHeaders = {
    "Content-Type": "application/json",
    "Token": AppConstants.token,
    "Access-Control-Allow-Origin": '*',
  }

  return new Headers(initHeaders)
}

export const request = (params, getDataAdapter = null) => {
  const controller = new AbortController()
  const url = AppConstants.apiUrl + params.url

  const promiseOptions = {
    signal: controller.signal,
    method: params.method,
    headers: getHeaders()
  }

  if (params.data) {
    promiseOptions.body = JSON.stringify(params.data)
  }

  const promise = fetch(url, promiseOptions)
    .then(response => {
      return response.json().then(data => data)
    })
    .then(res => {
      if (res?.status === 'error') {
        console.log('error result', res)

        return res
      }

      if (getDataAdapter) {
        return getDataAdapter(res)
      } else {
        return res
      }
    })

  return ({
    promise,
    controller,
    promiseOptions,
  })
}
