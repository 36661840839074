import React, {useEffect, useState} from 'react'
import { Link } from 'react-scroll'
import Container from "./Container";
import logo from "../public/icons/logo.svg";
import {FormattedMessage} from "react-intl";
import BaseButton from "./BaseButton";
import useScrollPosition from "../hooks/scroll_position_hook";
import {SCROLL_POSITION_UPDATE_TIMEOUT} from "../screens/scroll_screens/const";
import {createPortal} from "react-dom";
import {AnimatePresence} from "framer-motion";
import BackCallModal from "./BackCallModal";
import {appSelectors} from "../models/appModels";
import {localeSelectors} from "../models/locale";

const Header = ({scrollWidth = '0px', isCasePage = false}) => {
  const [isDark, setIsDark] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const setIsBlockScroll = appSelectors.isBlockScroll.useSetValue()
  const [locale, setLocale] = localeSelectors.useState()

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  useScrollPosition(({currPos}) => {
    const scrollPos = -currPos.y
    const breakPoint = document?.documentElement.scrollHeight - window?.innerHeight - window?.innerHeight * 0.1

    if (scrollPos >= breakPoint && !isDark) {
      setIsDark(true)
    } else if (isDark && scrollPos < breakPoint) {
      setIsDark(false)
    }
  }, [isDark], null, false, SCROLL_POSITION_UPDATE_TIMEOUT)

  const logoLetters = ['R', 'O', 'C', 'K', 'I', 'T']
  const anchorLinks = [
    {
      to: 'cases',
      textId: 'cases'
    },
    {
      to: 'stack',
      textId: 'stack'
    },
    {
      to: 'team',
      textId: 'team'
    },
  ]

  const handleOpenModal = () => {
    setIsBlockScroll(true)
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const onExitModal = () => {
    setIsBlockScroll(false)
    return null
  }

  const handleChangeLocale = () => {
    if (locale === 'ru') {
      setLocale('en')
    } else if (locale === 'en') {
      setLocale('ru')
    }
  }

  return (
    <Container customClass={`header ${isDark ? 'dark' : ''}`}>
      <header style={{paddingRight: scrollWidth}}>
        <div className="header__logo">
          {logoLetters.map((id) => {
            return (
              <svg key={id}>
                <use href={`${logo.src}#${id}`}/>
              </svg>
            )
          })}
        </div>

        {!isCasePage &&
          <div
            className="header__links"
          >
            {
              anchorLinks.map((link) => {
                return (
                  <Link
                    to={link.to}
                    smooth
                    duration={1000}
                    key={link.textId}
                  >
                    <FormattedMessage id={link.textId}/>
                  </Link>
                )
              })
            }
          </div>
        }


        <div className={'header__btns'}>
          <button
            className={'header__locale'}
            onClick={handleChangeLocale}
          >
            <FormattedMessage id={'locale'}/>
          </button>

          <BaseButton
            text={<FormattedMessage id={'call_form_btn'}/>}
            onClick={handleOpenModal}
          />
          {/*<Link*/}
          {/*  to={'contacts'}*/}
          {/*  smooth*/}
          {/*  duration={1000}*/}
          {/*>*/}
          {/*  <BaseButton*/}
          {/*    text={<FormattedMessage id={'call_form_btn'}/>}*/}
          {/*    // onClick={handleOpenModal}*/}
          {/*  />*/}
          {/*</Link>*/}
        </div>
      </header>

      {mounted && createPortal(
        <AnimatePresence
          initial={false}
          mode={'wait'}
          onExitComplete={onExitModal}
        >
          {isOpenModal
            ?  <BackCallModal handleCloseModal={handleCloseModal}/>
            : null
          }

        </AnimatePresence>,
        document.getElementById('contentWrapper')
      )}
    </Container>
  )
}

export default Header
