import React from 'react'

const Container = ({children, customClass = '', id}) => {
  return (
    <div id={id} className={`container ${customClass}`}>
      {children}
    </div>
  )
}

export default Container
