import StoreModelFabric from "./contentModelFabric";

const isSmoothLinkModel = new StoreModelFabric(false)
const startTouchXModel = new StoreModelFabric(0)
const isPreloaderShowModel = new StoreModelFabric(true)
const isBlockScrollModel = new StoreModelFabric(true)
const scrollPosModel = new StoreModelFabric(0)
const isHidePreloaderModel = new StoreModelFabric(false)
const isLettersMovingModel = new StoreModelFabric(false)

export const appSelectors = {
  isSmoothLink: isSmoothLinkModel.createSelectors(),
  startTouchX: startTouchXModel.createSelectors(),
  isPreloaderShow: isPreloaderShowModel.createSelectors(),
  isBlockScroll: isBlockScrollModel.createSelectors(),
  scrollPos: scrollPosModel.createSelectors(),
  isHidePreloader: isHidePreloaderModel.createSelectors(),
  isLettersMoving: isLettersMovingModel.createSelectors()
}
