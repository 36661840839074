import React from "react";

const BaseTitle = ({text, Tag = "h2", isMedium = false, children}) => {
  return (
    <Tag className={isMedium ? 'base-title-medium' : 'base-title'}>
      {text ?? children}
    </Tag>
  )
}

export default BaseTitle
