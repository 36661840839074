import React from "react";

export const MAIL = 'info@rockitstudio.ru'
export const TG = 'https://t.me/RockIT_Studio'
export const WA = 'https://wa.me/89630942992'

// Реквизиты
export const ADDRESS = 'Россия, 455000, Челябинская область, г. Магнитогорск, ул. Белинского, 33 А'
export const CURRENT_ACCOUNT = 'Расчетный счет\n40702810372000042550'
export const CORRESPONDENT_ACCOUNT = 'Корреспондентский счет\n30101810700000000602'
export const BANK_INFO = 'Наименование банка\nЧЕЛЯБИНСКОЕ ОТДЕЛЕНИЕ N8597 ПАО СБЕРБАНК г.\u00A0Челябинск'
export const TAX_INFO = 'ИНН 7456042923\nОГРН 1197456028846\nКПП 745601001'
export const TAX_INFO_2 = 'БИК 047501602\nТел. 8-982-340-08-97'

export const ALL_INFO = `${ADDRESS}; ${CURRENT_ACCOUNT}; ${CORRESPONDENT_ACCOUNT}; ${BANK_INFO}; ${TAX_INFO}; ${TAX_INFO_2}`