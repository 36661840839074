import case1 from '../../public/images/cases/case_1.svg'
import case2 from '../../public/images/cases/case_2.svg'
import case3 from '../../public/images/cases/case_3.svg'
import case4 from '../../public/images/cases/case_4.svg'
import case5 from '../../public/images/cases/case_5.svg'
import case6 from '../../public/images/cases/case_6.svg'

export const CASES_LIST = [
  {
    id: 1,
    textId: 'case_1_descr',
    img: case1
  },
  {
    id: 2,
    textId: 'case_2_descr',
    img: case2
  },
  {
    id: 3,
    textId: 'case_3_descr',
    img: case3
  },
  {
    id: 4,
    textId: 'case_4_descr',
    img: case4
  },
  {
    id: 5,
    textId: 'case_5_descr',
    img: case5
  },
  {
    id: 6,
    textId: 'case_6_descr',
    img: case6
  },
]

export const SCROLL_POSITION_UPDATE_TIMEOUT = 12
