import React from 'react'

const  BaseButton = (
  {
    text,
    Tag = 'button',
    children,
    onClick = () => {},
    classes = '',
    isRound = false
  }) => {
  return (
    <Tag className={`base-button ${classes} ${isRound ? 'base-button_round' : ''}`} onClick={onClick}>
      {text ?? children}
    </Tag>
  )
}

export default BaseButton
