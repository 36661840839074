import {request} from "./request";

export const endpoints =  {
  feedbackEndpoint: (data) => {
    const params = {
      url: 'feedback/',
      method: 'POST',
      data
    }

    return request(params)
  }
}
