import React from 'react'
import BaseCaption from "./BaseCaption";

const BaseInput = ({label, name, isTextField = false, formSelectors}) => {
  const value = formSelectors.useFormDataValue(name)
  const validations = formSelectors.useFormValueValidation(name)
  const setValue = formSelectors.useSetFormDataValue(name)

  const handleInput = (e) => {
    const {value} = e.target

    setValue(value)
  }

  return (
    <div className={`base-input ${isTextField ? 'base-input_textarea' : ''}`}>
      <BaseCaption isSmall Tag={'label'} props={{htmlFor: name}}>{label}</BaseCaption>

      <div >
        {isTextField
          ? (
            <textarea
              id={name}
              value={value}
              name={name}
              rows={3}
              onChange={handleInput}
            />
          )
          : (
            <input
              className={!!validations.length ? 'error' : ''}
              id={name}
              value={value}
              name={name}
              onChange={handleInput}
            />
          )
        }
      </div>

    </div>
  )
}

export default BaseInput
