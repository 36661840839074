import React, {useEffect, useState} from 'react'
import BaseTitle from "./BaseTitle";
import BaseHeadline from "./BaseHeadline";
import BaseInput from "./BaseInput";
import BaseCaption from "./BaseCaption";
import Image from "next/image";
import close from '../public/images/releases/close.svg'
import {MAIL, TG, WA} from "../screens/contacts_screen/const";
import img from '../public/images/callback/callback.svg'
import logo from '../public/images/callback/logo.svg'
import tg from '../public/images/callback/tg.svg'
import wa from '../public/images/callback/wa.svg'
import mail from '../public/images/callback/mail.svg'
import {FormattedMessage} from "react-intl";
import {formSelectors} from "../models/backcall_form";
import BaseButton from "./BaseButton";
import {motion} from "framer-motion"
import {endpoints} from "../api_service/endpoints";
import {localeSelectors} from "../models/locale";

const BackCallModal = ({handleCloseModal}) => {
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const resetForm = formSelectors.useResetForm()
  const isValidForm = formSelectors.useIsFormValid()
  const onSubmitForm = formSelectors.useOnClickSubmit()
  const setOnValidSubmit = formSelectors.useSetOnValidSubmit()

  const locale = localeSelectors.useValue()

  const onValidSubmit = (data) => {
    setIsSending(true)

    const {promise} = endpoints.feedbackEndpoint(data)

    promise
      .then(res => {
        if (res?.status === 'ok') {
          handleCloseModal()
        }
      })
      .catch(err => {
        console.log('feedbackEndpoint error', err)
      })
      .finally(() => setIsSending(false))
  }

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      resetForm()
    }
  }, [])

  const variants = {
    hidden: {
      y: '-110vh',
      ease: 'linear',
      transition: {
        duration: 0.3,
      },
    },
    visible: {
      y: '0',
      ease: 'linear',
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      y: '-110vh',
      ease: 'linear',
      transition: {
        duration: 0.3,
      },
    }
  }

  const handleSubmitForm = () => {
    if (!isValidForm || isSending) return

    onSubmitForm()
  }

  return (
    <motion.div
      className={`callback-modal ${locale === 'ru' ? 'callback-modal_ru' : ''}`}
      variants={variants}
      initial={'hidden'}
      animate={'visible'}
      exit={'exit'}
    >
      <div className="callback-modal__left">
        <div className="callback-modal__logo">
          <Image
            src={logo}
            alt={'logo'}
          />
        </div>

        <BaseTitle>
          <FormattedMessage id={'callback_title'}/>
        </BaseTitle>

        <div className="callback-modal__img">
          <Image
            src={img}
            alt={'callback'}
          />
        </div>
      </div>

      <div className="callback-modal__right">
        <div className="callback-modal__form">
          <BaseHeadline>
            <FormattedMessage id={'callback_title_2'}/>
          </BaseHeadline>

          <div className={'callback-modal__form-fields'}>
            <BaseInput
              id={'name'}
              name={'name'}
              formSelectors={formSelectors}
              label={<FormattedMessage id={'callback_name'}/>}
            />

            <BaseInput
              id={'phone_or_mail'}
              name={'phone_or_mail'}
              formSelectors={formSelectors}
              label={<FormattedMessage id={'callback_contact'}/>}
            />

            <BaseInput
              id={'description'}
              name={'description'}
              label={<FormattedMessage id={'callback_message'}/>}
              formSelectors={formSelectors}
              isTextField
            />
          </div>

          {/*<div className="callback-modal__privacy" onClick={() => setIsPrivacyChecked(!isPrivacyChecked)}>*/}
          {/*  <div className="callback-modal__check-box">*/}
          {/*    {isPrivacyChecked*/}
          {/*      ? <div className={'callback-modal__checked'}>*/}
          {/*        <Image*/}
          {/*          src={close}*/}
          {/*          alt={'checked'}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      : null*/}
          {/*    }*/}
          {/*  </div>*/}

          {/*  <BaseCaption isSmall>*/}
          {/*    <FormattedMessage*/}
          {/*      id={'callback_privacy'}*/}
          {/*      values={{*/}
          {/*        a: (chunks) => <a href={'#'}>{chunks}</a>*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </BaseCaption>*/}
          {/*</div>*/}

          <button
            className="callback-modal__btn" onClick={handleSubmitForm}
            disabled={isSending}
          >
            <BaseHeadline>
              <FormattedMessage id={'callback__btn'}/>
            </BaseHeadline>
          </button>
        </div>

        <div className="callback-modal__description">
          <BaseButton
            isRound
            onClick={handleCloseModal}
          >
            <div className="release-modal__close">
              <Image
                src={close}
                alt={'close'}
              />
            </div>
          </BaseButton>

          <BaseHeadline>
            <FormattedMessage id={'callback_title_3'}/>
          </BaseHeadline>

          <BaseCaption isSmall>
            <FormattedMessage id={'callback_caption'}/>
          </BaseCaption>

          <div className="callback-modal__contacts">
            <a target={'_blank'} href={TG} className="callback-modal__link">
              <div className="callback-modal__link-icon">
                <Image src={tg} alt={'tg'} />
              </div>
              <span>Telegram</span>
            </a>
            <a target={'_blank'} href={WA} className="callback-modal__link">
              <div className="callback-modal__link-icon">
                <Image src={wa} alt={'wa'} />
              </div>
              <span>WhatsApp</span>
            </a>
            <a target={'_blank'} href={`mailto:${MAIL}`} className="callback-modal__link">
              <div className="callback-modal__link-icon">
                <Image src={mail} alt={'mail'} />
              </div>
              <span>{MAIL}</span>
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default BackCallModal
