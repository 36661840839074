import FormModelFabric from "./formModelFabric";

const formModel = new FormModelFabric({
  initialData: {
    name: '',
    phone_or_mail: '',
    description: ''
  },
  initialValidations: {
    name: [],
    phone_or_mail: [],
  },
  getValidators: (formData) => {
    return {
      name: (value) => {
        return !!value
          ? []
          : ['required']
      },
      phone_or_mail: (value) => {
        return !!value
          ? []
          : ['required']
      },
    }
  }
})

export const formSelectors = formModel.createSelectors()
